import oAuth from "../libs/oauth"
import { mapGetters } from "vuex"

export default {
	name: "security-mixin",
	computed: {
		...mapGetters(["user", "authorities", "isAuthenticated", "isImpersonated", "previousUser", "loginError", "refreshError", "loginAsError"]),
		hasSecurityErrors() { return this.$store.getters.hasErrors },
		nomeLoggato() { return this.user.nome },
		readableRoles() {
			const authorities = this.authorities
			const roles = []
			if(oAuth.hasAnyRoles(["DEVELOPER"], authorities)) roles.push({ value: "DEVELOPER", text: "Sviluppatore"})
			if(oAuth.hasAnyRoles(["ADMIN"], authorities)) {
				roles.push({ value: "ADMIN", text: "Amministratore" }, { value: "COMPAGNIA", text: "Compagnia" }, { value: "SINISTRI", text: "Sinistri" })
				if(oAuth.isSocieta('RCI')) roles.push({ value: "ESMO", text: "Esmo" })
				roles.push({ value: "REFERENTE", text: "Referente" }, { value: "RIPARATORE", text: "Riparatore" })
			}
			return roles
		},
		writableRoles() { return this.readableRoles }
	},
	methods: {
		login: oAuth.login.bind(oAuth),
		logout: oAuth.logout.bind(oAuth),
		loginAs: oAuth.loginAs.bind(oAuth),
		hasRoles: oAuth.hasRoles.bind(oAuth),
		isSocieta: oAuth.isSocieta.bind(oAuth)
	}
}