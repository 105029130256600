import Vue from "vue"
import numeral from "numeral"
import "numeral/locales"

export function numeralFilter(number, format = undefined) {
  return numeral(number).format(format);
}

export function formatDecimalFilter(number, decimals = 2) {
  const format = `0,0.${"0".repeat(decimals < 1 ? 1 : decimals)}`;
  return numeralFilter(number, format);
}

const VueNumeral = {
  install(Vue, options) {
    const locale = options && options.locale ? options.locale : "it";
    numeral.locale(locale);
    Vue.prototype.$numeral = Vue.numeral = numeral;
    Vue.filter("numeral", numeralFilter);
    Vue.filter("formatDecimal", formatDecimalFilter)
  }
};

Vue.use(VueNumeral);