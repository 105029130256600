import { debounce } from "lodash"
export const DEFAULT_MAX = +process.env.VUE_APP_DEFAULT_MAX
export const DEFAULT_PAGE = +process.env.VUE_APP_DEFAULT_PAGE

export default {
	name: "pagination-mixin",
	props: {
		router: {
			type: Boolean,
			default: true
		},
		page: {
			type: [String, Number],
			default: DEFAULT_PAGE
		},
		max: {
			type: [String, Number],
			default: DEFAULT_MAX
		},
		sort: [String, Array],
		order: [String, Array],
		q: String
	},
	data: () => ({
		paginationParams: {}
	}),
	methods: {
		doPagination() {
			console.debug("pagination-mixin do pagination", this.paginationParams)
		},
		resetPagination() {
			console.debug("pagination-mixin reset pagination", this.apiConfig)
			if(this.paginationParams.page !== DEFAULT_PAGE || this.paginationParams.max !== DEFAULT_MAX) this.updatePagination({ page: DEFAULT_PAGE, max: DEFAULT_MAX })
			else this.doPagination()
		},
		onSort(pagination) {
			const paginationParams = { ...this.paginationParams }
			if(this.paginationParams.sort !== pagination.sort) paginationParams.sort = pagination.sort
			if(this.paginationParams.order !== pagination.order) paginationParams.order = pagination.order
			if(!pagination.sort) {
				delete paginationParams.sort
				delete paginationParams.order
			}
			this.updatePagination(paginationParams)
		},
		onPage(pagination) {
			const paginationParams = { ...this.paginationParams }
			if(!!pagination.page && this.paginationParams.page !== pagination.page) paginationParams.page = pagination.page
			if(!!pagination.max && this.paginationParams.max !== pagination.max) paginationParams.max = pagination.max
			this.updatePagination(paginationParams)
		},
		updatePagination(pagination) {
			console.debug("pagination-mixin update pagination", pagination)
			if(this.router) this.$router.push({ query: pagination }).catch((err)=>{
				console.log('router warning: ' , err)
			});
			else this.paginationParams = pagination
		}
	},
	created() {
		if(this.router) this.$watch("$route", {
			handler() {
				this.paginationParams = {
					page: +this.$route.query.page || this.page,
					max: +this.$route.query.max || this.max,
					sort: this.$route.query.sort || this.sort,
					order: this.$route.query.order || this.order,
					q: this.$route.query.q || this.q
				}
				this.doPagination()
			},
			immediate: true
		})
		else {
			this.paginationParams = {
				page: this.page,
				max: this.max,
				sort: this.sort,
				order: this.order,
				q: this.q
			}
			this.$watch("paginationParams", {
				handler: "doPagination",
				immediate: true
			})
		}
	}
}