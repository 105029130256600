
export const SET_RICERCA = "SET_RICERCA"
export const SET_TAB_SINISTRI = "SET_TAB_SINISTRI"
export const CLEAR_SINISTRI = "CLEAR_SINISTRI"
export const SET_SHOW_ALERT = "SET_SHOW_ALERT"

const sinistri = {
	state: {
		ricerca: "",
		tabSinistri: 0,
		showAlert: false
	},
	getters: {
		ricerca: state => state.ricerca,
		tabSinistri: state => state.tabSinistri,
		showAlert: state => state.showAlert,
	},
	mutations: {
		[SET_RICERCA]: (state, ricerca) => state.ricerca = ricerca,
		[SET_TAB_SINISTRI]: (state, tabIndex) => state.tabSinistri = tabIndex,
		[CLEAR_SINISTRI]: state => {
			state.ricerca = ""
			state.tabSinistri = 0
		},
		[SET_SHOW_ALERT]: (state, showAlert) => state.showAlert = showAlert
	},
	actions: {
		setRicerca({ commit }, ricerca) {
			commit(SET_RICERCA, ricerca)
		},
		setTabSinistri({ commit }, tabIndex) {
			commit(SET_TAB_SINISTRI, tabIndex)
		},
		clearSinistri({ commit }) {
			commit(CLEAR_SINISTRI)
		},
		showAlert({ commit }) { commit(SET_SHOW_ALERT, true) },
		hideAlert({ commit }) { commit(SET_SHOW_ALERT, false) }
	}
}

export default sinistri