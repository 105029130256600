<template>
	<div class="v-table mb-2">
		<v-text-field v-if="search" :value="paginationParams.q" @input="debounceSearch"
									placeholder="Ricerca" solo prepend-inner-icon="fas fa-search"
									clearable hide-details tile></v-text-field>
		<div class="table-overflow mt-3">
			<table class="awesome-table" :class="themeClasses">
				<colgroup>
					<slot></slot>
				</colgroup>
				<caption v-if="$slots.caption"><slot name="caption"></slot></caption>
				<thead v-if="hasHeader">
					<v-table-row key="v-table-header">
						<v-column-header v-for="(column, columnIndex) in columns"
														 :key="`v-column-cell-${columnIndex}`"
														 :column="column"
														 :items="rows"
														 :sort="paginationParams.sort"
														 :order="paginationParams.order"
														 :header-props="{ 'sort-icon' : sortIcon }"
														 @update-sort="onSort"
                             align="start"
						/>
					</v-table-row>
					<tr v-if="loading" class="v-datatable__progress">
						<th :colspan="columns.length">
							<slot name="progress">
								<v-progress-linear indeterminate color="primary"/>
							</slot>
						</th>
					</tr>
				</thead>
				<tbody>
					<v-table-row v-for="(item, index) in rows" :key="`v-table-row-${index}`" :item="item" :index="index" :row-class="dataRowClass">
						<v-column-cell v-for="(column, columnIndex) in columns" :key="`v-column-cell-${columnIndex}`" :column="column" :item="item" :index="index"/>
					</v-table-row>
					<v-table-row v-if="rows.length === 0" key="v-table-empty-row">
						<v-column-cell :colspan="columns.length" class="text-xs-center" v-text="noDataText"></v-column-cell>
					</v-table-row>
				</tbody>
				<tfoot v-if="hasFooter">
					<v-table-row key="v-table-footer">
						<v-column-footer v-for="(column, columnIndex) in columns" :key="`v-column-cell-${columnIndex}`" :column="column" :items="rows"/>
					</v-table-row>
				</tfoot>
			</table>
		</div>
		<v-table-pagination v-if="pagination" :max="paginationParams.max" :page="paginationParams.page" :total="getTotal()" @update-pagination="onPage"/>
	</div>
</template>

<script>
	import themeable from "vuetify/lib/mixins/themeable"
	import paginationMixin, { DEFAULT_MAX, DEFAULT_PAGE } from "@/mixins/pagination-mixin"
	import vTableRow from "./v-table-row"
	import vColumnCell from "./v-column-cell"
	import vColumnHeader from "./v-column-header"
	import vColumnFooter from "./v-column-footer"
	import vTablePagination from "./v-table-pagination"

	export default {
		name: "v-table",
		mixins: [themeable, paginationMixin],
		components: { vTableRow, vColumnHeader, vColumnCell, vColumnFooter, vTablePagination },
		props: {
			items: {
				type: Array,
				default: () => ([])
			},
			search: Boolean,
			pagination: {
				type: Boolean,
				default: true
			},
			sortIcon: {
				type: String,
				default: "fas fa-caret-up"
			},
			noDataText: {
				type: String,
				default: "Nessun elemento presente"
			},
			dataRowClass: [String, Object, Function]
		},
		data: () => ({
			rows: [],
			columns: [],
			loading: false
		}),
		computed: {
			hasHeader() { return this._.reduce(this.columns, (total, column) => this._.isNil(column.renderHeader(this.rows)) ? total : total + 1, 0) > 0; },
			hasFooter() { return this._.reduce(this.columns, (total, column) => this._.isNil(column.renderFooter(this.rows)) ? total : total + 1, 0) > 0; },
		},
		methods: {
			readColumns(forceUpdate = false) {
				const columns = this.$slots.default
						.filter(child => !!child.componentInstance && child.componentInstance.$options.name === "v-table-column")
						.map(column => column.componentInstance);
				let needUpdate = false;
				if(columns.length !== this.columns.length) needUpdate = true;
				else needUpdate = this._.differenceWith(columns, this.columns, (column, oldColumn) => column.columnId === oldColumn.columnId).length !== 0;
				console.debug("v-table read columns", forceUpdate, needUpdate)
				if(needUpdate || forceUpdate) {
					this.columns = columns;
					console.debug("v-table-columns update columns");
				}
			},
			getTotal() { return this.items.length },
			doSearch(search) {
				const paginationParams = { page: DEFAULT_PAGE, max: DEFAULT_MAX }
				if(!this._.isNil(search)) paginationParams.q = search
				this.updatePagination(paginationParams)
			},
			doPagination() {
				console.debug("v-table do pagination", this.paginationParams)
				this.loading = true
				if(this.pagination) {
					const start = this.paginationParams.max * (this.paginationParams.page - 1)
					const end = start + this.paginationParams.max
					this.rows = this._.sortBy(this.items.filter(item => {
						if(this.search && !!this.paginationParams.q) {
							let check = false
							_.each(this.columns, column => {
								if(!_.isNil(column.prop)) {
									const value = _.get(item, column.prop)
									check |= value.toString().indexOf(this.paginationParams.q) >= 0
								}
							})
							return !!check
						}
						return true
					}), item => {
						if(!!this.sort) {
							if(this.paginationParams.order === ASC_ORDER) return _.get(item, this.sort)
							else if(this.paginationParams.order === DESC_ORDER) return -_.get(item, this.sort)
						}
						return true
					}).slice(start, end)
				} else this.rows = this.items
				this.loading = false
			}
		},
		created() {
			this.debounceSearch = this._.debounce(this.doSearch, 500)
		},
		mounted() { this.readColumns(true); },
		updated() { this.readColumns(); }
	}
</script>

<style>
  div.table-overflow {
    overflow-x: auto;
  }

  table.awesome-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgba(0,0,0,0.12);
  }

  table.awesome-table th.fit,
  table.awesome-table td.fit {
    white-space: nowrap;
    width: 1%;
  }

  table.awesome-table th.sortable,
  table.awesome-table td.sortable {
    outline: none;
  }
</style>