import "@babel/polyfill"
import Vue from "vue"
import "./plugins/lodash"
import "./plugins/axios"
import "./plugins/numeral"
import "./plugins/moment"
import "./plugins/global-components"
import "./plugins/utils"
import i18n from "./plugins/i18n"
import PortaleSinistri from "./portale-sinistri.vue"
import router from "./router"
import store from "./store"
if(process.env.VUE_APP_SOCIETA === "VW") import("./assets/stylesheets/vw.scss")
if(process.env.VUE_APP_SOCIETA === "BMW") import("./assets/stylesheets/bmw.scss")
if(process.env.VUE_APP_SOCIETA === "RCI") import("./assets/stylesheets/rci.scss")
if(process.env.VUE_APP_SOCIETA === "ALL") import("./assets/stylesheets/call-center.scss")
import vuetify from "./plugins/vuetify"
import { sync } from "vuex-router-sync"
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.component('multiselect', Multiselect);
Vue.config.productionTip = false
sync(store, router)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(PortaleSinistri)
}).$mount("#app")
