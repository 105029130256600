<template>
	<v-card class="panel" :class="{ 'with-action': detailsAction }">
		<v-card-title class="pa-0">
			<v-toolbar flat height="40px" color="#f7f7f7">
				<v-icon class="ml-2" v-if="titleIcon">{{titleIcon}}</v-icon>
				<v-toolbar-title class="w-100 text-center ml-2">{{title}}</v-toolbar-title>
			</v-toolbar>
		</v-card-title>
		<v-card-text class="pa-5">
			<slot></slot>
		</v-card-text>
		<v-card-actions v-if="detailsAction" class="panel-actions">
			<v-spacer/>
			<v-btn text color="primary" :to="detailRoute" input-value="true" id="dettaglioBtn" class="body-2 font-weight-medium">{{detailLabel}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	export default {
		name: "v-panel",
		props: {
			title: String,
			titleIcon: String,
			detailsAction: Boolean,
			detailRoute: Object,
            detailLabel: { type: String, default: "Vedi dettagli" }
		}
	}
</script>

<style scoped>
  .panel-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 52px;
  }
  #dettaglioBtn {
    background-color: rgba(244, 114, 22, 0.1)
  }
</style>