import axios from "axios"

export const UPDATE_CONTEGGIO_NOTIFICHE = "UPDATE_CONTEGGIO_NOTIFICHE"
export const CLEAR_CONTEGGIO_NOTIFICHE = "CLEAR_CONTEGGIO_NOTIFICHE"

const notifiche = {
	state: {
		daLeggere: 0
	},
	getters: {
		conteggioNotificheDaLeggere: state => state.daLeggere
	},
	mutations: {
		[UPDATE_CONTEGGIO_NOTIFICHE]: (state, totale) => state.daLeggere = totale,
		[CLEAR_CONTEGGIO_NOTIFICHE]: state => state.daLeggere = 0
	},
	actions: {
		async fetchConteggioNotifiche({ commit }) {
			const response = await axios.get("/sinistri/alerts")
			commit(UPDATE_CONTEGGIO_NOTIFICHE, response.data.totale)
		},
		reset({ commit }) { commit(CLEAR_CONTEGGIO_NOTIFICHE) }
	}
}

export default notifiche