<script>
	const NO_ORDER = process.env.VUE_APP_NO_ORDER
	const ASC_ORDER = process.env.VUE_APP_ASC_ORDER
	const DESC_ORDER = process.env.VUE_APP_DESC_ORDER

	export default {
		name: "v-column-header",
		props: {
			items: Array,
			column: {
				type: Object,
				required: true
			},
			sortIcon: {
				type: String,
				default: "fas fa-caret-up"
			},
			sort: String,
			order: String
		},
		computed: {
			prop() { return this.column.sortProp || this.column.prop },
			sortable() { return this.column.sortable },
			isCurrentSort() { return this.sort === this.prop },
			sorted() { return this.sortable && this.order !== NO_ORDER && this.isCurrentSort },
			isAsc() { return this.sorted && this.order === ASC_ORDER },
			isDesc() { return this.sorted && this.order === DESC_ORDER },
			classes() { return this.column.classes }
		},
		methods: {
			onSort() {
                const current = this.isCurrentSort ? this.order : NO_ORDER
                const order = current === "" ? ASC_ORDER : current === ASC_ORDER ? DESC_ORDER : NO_ORDER
                const params = {}
                if(order) {
                    params.sort = this.prop;
                    params.order = order;
                }
                console.debug("v-column-header update sort", params)
                this.$emit("update-sort", params)
			},
			genSortIcon(h) {
				return h("v-icon", null, `${this.sortIcon} mx-1`)
			},
			renderHeader() {
				if(this.$scopedSlots.default) return this.$scopedSlots.default(this.items)
				if(this.$slots.default) return this.$slots.default
				if(!this._.isNil(this.column)) return this.column.renderHeader({ items: this.items, sort: this.onSort.bind(this) })
			}
		},
		render(h) {
			const classes = (this.column && this.column.classes) || {}
			const css = this.column && this.column.css
			const headerData = {
				staticClass: "v-column-header pa-2",
				class: {
					"sortable": this.sortable,
					"sorted": this.sorted,
					"asc": this.isAsc,
					"desc": this.isDesc,
					...classes
				},
				style: css,
				on: {}
			}
			const children = []
			children.push(h("span", null, [this.renderHeader()]))
      if(this.sortable) {
        headerData.on.click = this.onSort
        children.push(this.genSortIcon(h))
      }
			return h("th", headerData, [children])
		}
	}
</script>

<style lang="stylus" scoped>
	.v-column-header
		&.sortable
			cursor: pointer
			$sorted-color: transparentize(#000, 0.13)
			&:hover, &.sorted
				color: $sorted-color
			.v-icon
				vertical-align: text-bottom
				display: inline-block
				font-size: 16px
				color: $sorted-color
			&:hover:not(.sorted) .v-icon
				opacity: 0.5 !important
			&:not(.sorted) .v-icon
				opacity: 0
			&.sorted .v-icon
				opacity: 1
				-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
				transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
			&.sorted.desc .v-icon
				-webkit-transform: rotate(-180deg)
				transform: rotate(-180deg)
</style>