<template>
	<tr class="v-table-row" :class="classes">
		<slot v-bind="{ item, columns }">
			<v-column-cell v-for="column in columns" :key="`v-column-cell-${index}`"
										 :column="column"
										 :item="item"
										 :index="index"
			/>
		</slot>
	</tr>
</template>

<script>
	import vColumnCell from "./v-column-cell"

	export default {
		name: "v-table-row",
		components: { vColumnCell },
		model: {
			prop: "item",
			event: "item:change"
		},
		props: {
			item: { required: false },
			index: Number,
			columns: Array,
			rowClass: [String, Object, Function]
		},
		computed: {
			classes() {
				if(this._.isFunction(this.rowClass)) return this.rowClass({ item: this.item, index: this.index })
				else return this.rowClass
			}
		}
	}
</script>