<template>
	<v-dialog v-model="editDialog" persistent max-width="320">
		<template #activator="{ on }">
			<v-text-field :value="sinistro.dataAccettazione" v-bind="$attrs" readonly class="data-accettazione" :class="{ caption: small }" :dense="small" hide-details v-on="on">
				<template #prepend v-if="icon">
					<v-icon :small="small">far fa-calendar-alt</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-card>
			<v-card-title class="headline text-center title pl-15 pr-15" style="line-height: 20px">
				Selezionare la data di accettazione
			</v-card-title>
			<v-date-picker v-model="dataLavorazione" locale="it" :first-day-of-week="1" :max="new Date().toISOString()" color="primary" flat/>
			<v-card-actions>
				<v-btn text class="body-2 font-weight-regular" color="black" @click="closeDialog">Annulla</v-btn>
				<v-spacer/>
				<v-btn text class="body-2 font-weight-regular" color="primary" @click="saveDataAccettazione" :loading="loading">Conferma</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "lavorazione-field",
		props: {
			sinistro: {type: Object, required: true},
			icon: Boolean,
			small: Boolean,
			edit: Boolean,
			saveDisabled: Boolean
		},
		data() {
			return {
				id: null,
				numeroSinistro: "",
				dataLavorazione: "",
				innerEditDialog: false,
				clearDialog: false,
				loading: false
			}
		},
		computed: {
			params() {
				return {
					id: this.id,
					dataAccettazione: !!this.dataLavorazione ? this.$moment(this.dataLavorazione).format("DD/MM/YYYY") : this.dataLavorazione
				}
			},
			editDialog: {
				get() {
					return this.edit || this.innerEditDialog
				},
				set(value) {
					this.innerEditDialog = value
					this.$emit("update:edit", value)
				}
			}
		},
		methods: {
			closeDialog() {
				this.editDialog = false
				this.clearDialog = false
			},
			async saveDataAccettazione() {
				try {
					this.loading = true
					if (!this.saveDisabled) await this.axios.post("/sinistri/modifica-data-accettazione", this.params)
					this.sinistro.dataAccettazione = this.dataLavorazione ? this.$moment(this.dataLavorazione, "YYYY-MM-DD").format("DD/MM/YYYY") : this.dataLavorazione
					this.$emit("input", this.sinistro.dataAccettazione)
					this.closeDialog()
				} finally {
					this.loading = false
				}
			},
		},
		watch: {
			sinistro: {
				handler({dataAccettazione, id, numeroSinistro, numero}) {
					this.id = id
					this.dataLavorazione = dataAccettazione ? this.$moment(dataAccettazione, "DD/MM/YYYY").format("YYYY-MM-DD") : dataAccettazione
					this.numeroSinistro = numeroSinistro || numero
				},
				immediate: true
			}
		}
	}
</script>