import Vue from "vue"
import lodash from "lodash"

const VueLodash = {
	install(Vue) {
		Vue._ = lodash;
		Object.defineProperty(Vue.prototype, "_", { get() { return lodash; } })
	}
};

Vue.use(VueLodash);