<script>
	import { VTable } from "./v-table"
	import apiMixin from "@/mixins/api-mixin"
    import { debounce } from "lodash"

	const TOTAL_HEADER = process.env.VUE_APP_TOTAL_HEADER

	export default {
		name: "v-remote-table",
		extends: VTable,
		mixins: [apiMixin],
		data: () => ({
			total: 0
		}),
		computed: {
			fetchConfig() {
				const config = { ...this.apiConfig }
				const paramsKey = config.method === "get" || this._.isNil(config.method) ? "params" : "data"
				if(this.pagination) config[paramsKey] = this._.defaults({}, this.paginationParams, config[paramsKey])
				return config
			}
		},
		methods: {
			async doPagination() {
                console.debug("v-remote-table do pagination NON debounce", this.fetchConfig)
                this.loading = true
                try {
                    const response = await this.axios.request(this.fetchConfig)
                    this.rows = response.data
                    this.total = +response.headers[TOTAL_HEADER]
                    this.onItemsFetched()
                    this.onFetch(response)
                } catch(e) {
                    this.$emit("fetch-error", e)
                } finally {
                    this.loading = false
                }
            },
			getTotal() { return this.total },
			onItemsFetched() { this.$emit("items-fetched", { rows: this.rows, total: this.total }) },
            onFetch(response) { this.$emit("fetched", response) }
		},
		watch: {
			api: {
				handler: "resetPagination",
                deep: true,
                immediate: true
			}
		}
	}
</script>

<style scoped>
	.v-table table.v-table:not(.hooverable) tbody tr:hover {
    background: inherit !important;
  }
  table.awesome-table tr, td, th {
    border: 1px solid rgba(0,0,0,0.12);
  }
</style>