<template>
	<v-autocomplete v-model="model"
					:items="items"
					:disabled="autocompleteDisabled"
					v-bind="$attrs"
					:loading="loading"
					v-if="showAutocomplete"
					:append-outer-icon="clearable && !!value ? 'fas fa-times-circle' : ''"
					@click:append-outer="clear">
		<template v-slot:item="props" v-if="$scopedSlots.default">
			<slot v-bind="props"></slot>
		</template>
	</v-autocomplete>
	<v-text-field v-model="model" v-bind="$attrs" :disabled="autocompleteDisabled" :loading="loading" v-else-if="showFallback" :append-icon="clearable && !!value ? 'fas fa-times-circle' : ''" @click:append="clear"/>
</template>

<script>
	import apiMixin from "@/mixins/api-mixin"

	export default {
		name: "v-remote-select",
		mixins: [apiMixin],
		props: {
			value: { required: false },
			disabled: {
				type: Boolean,
				default: false
			},
			inputFallback: Boolean,
			clearable: Boolean,
			transformItem: {
				type: Function,
				default: item => item
			},
			defaultFirstIfUndefined: Boolean
		},
		data: () => ({
			loading: false,
			items: []
		}),
		methods: {
			async fetchItems() {
				if(!this.disabled) try {
					this.loading = true
					const response = await this.axios.request(this.apiConfig)
					this.items = response.data.map(item => this.transformItem(item))
					if(!(!!this.model) && this.defaultFirstIfUndefined) this.$emit("input", this._.head(this.items))
				} finally { this.loading = false }
			},
			clear() { if(this.clearable) this.$emit("input", "") }
		},
		computed: {
			model: {
				get() { return this.value },
				set(value) {
					this.$emit("input", value)
				}
			},
			autocompleteDisabled: {
				get() { return this.disabled || this.loading }
			},
			showAutocomplete: {
				get() {
					if(this.inputFallback) return this.items.length > 0
					return true
				}
			},
			showFallback: {
				get() {
					if(this.inputFallback) return this.items.length === 0
					return false
				}
			}
		},
		watch: {
			api: {
				handler: "fetchItems",
				deep: true,
				immediate: true
			}
		}
	}
</script>