
export default {
	name: "api-mixin",
	props: {
		api: {
			type: [String, Object],
			required: true
		},
	},
	computed: {
		apiConfig() {
			let config = {}
			if(this._.isString(this.api)) config = { url: this.api, method: "get" }
			else if(this._.isPlainObject(this.api)) config = { ...this.api }
			return config
		}
	}
}