import Vue from "vue"
import Router from "vue-router"
import oAuth, { routerBeforeEach } from "./libs/oauth"

Vue.use(Router);

function nestedComponent(name) { return { name: name, template: "<router-view/>"}; }

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      meta: { isAuthenticated: true },
      component: () => import(/* webpackChunkName: "layout" */ "./views/layout"),
      children: [
        {
          path: "",
          name: "home",
          component: () => {
            if(oAuth.isSocieta('ALL'))
              return import(/* webpackChunkName: "home" */ "./views/homeCallCenter")
            else if(oAuth.isSocieta('RCI'))
              return import(/* webpackChunkName: "home" */ "./views/homeRci")
            else
              return import(/* webpackChunkName: "home" */ "./views/home")
          },
          alias: "privacy"
        },
        {
          path: "documentazione",
          name: "documentazione",
          props: true,
          meta: { forSocieta: "RCI" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/rci/documentazione")
        },
        {
          path: "manuale",
          name: "manuale",
          meta: { roles: "ADMIN" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: {
            name: "man-test",
            template: "<div>Pagina manuale</div>"
          }
        },
        {
          path: "relitti",
          name: "gestione-relitti",
          meta: { roles: "RELITTI" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "relitti" */ "./views/sinistri/relitti")
        },
        {
          path: "utenti",
          name: "elenco-utenti",
          meta: { roles: "UTENTI" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || (oAuth.isSocieta('ALL') && !(oAuth.hasRoles('ADMIN') || oAuth.hasRoles('DEVELOPER'))));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "utenti" */ "./views/utenti/elenco-utenti")
        },
        {
          path: "utenti/:idPadre",
          name: "elenco-utenti-app",
          meta: { roles: "UTENTI" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          props: true,
          component: () => import(/* webpackChunkName: "utenti" */ "./views/utenti/elenco-utenti")
        },
        {
          path: "utente/:id?",
          name: "editor-utente",
          meta: { roles: "USER" },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || (oAuth.isSocieta('ALL') && !(oAuth.hasRoles('ADMIN') || oAuth.hasRoles('DEVELOPER'))));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          props: true,
          component: () => import(/* webpackChunkName: "utenti" */ "./views/utenti/editor-utenti")
        },
        {
          path: "account",
          name: "account",
          props: () => ({ id: oAuth.getUser("id") }),
          component: () => import(/* webpackChunkName: "utenti" */ "./views/utenti/editor-utenti")
        },
        {
          path: "cerca-sinistri",
          name: "cerca-sinistri",
          props: true,
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/sinistri/cerca-sinistri")
        },
        {
          path: "nuovo-sinistro",
          name: "nuovo-sinistro",
          props: (route) => ({ evento: route.params.evento, dataSinistro: route.params.dataSinistro }),
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "sinistri-editor" */ "./views/sinistri/nuovo-sinistro")
        },
        {
          path: "crea-sinistro",
          name: "crea-sinistro",
          props: (route) => ({ nuovo: true, evento: route.params.evento, dataSinistro: route.params.dataSinistro , societa: route.params.societa }),
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/sinistri/crea-sinistro")
        },
        {
          path: "sinistro-salvato",
          name: "sinistro-salvato",
          props: (route) => ({ sinistro: route.params.sinistro }),
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/sinistri/sinistro-salvato")
        },
        {
          path: "/sinistri/:id",
          name: "dettagli-sinistro",
          props: true,
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/sinistri/dettagli-sinistro")
        },
        {
          path: "mail/:id",
          name: "editor-mail",
          props: true,
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "sinistri" */ "./views/sinistri/editor-mail")
        },
        {
          path: "reportistica",
          name: "reportistica",
          props: true,
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => {
            if(oAuth.isSocieta('RCI'))
              return import(/* webpackChunkName: "sinistri" */ "./views/rci/reportistica-rci")
            else
              return import(/* webpackChunkName: "sinistri" */ "./views/sinistri/reportistica")
          }
        },
        {
          path: "notifiche-push",
          name: "notifiche-push",
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "notifiche-push" */ "@/views/rci/notifiche-push")
        },
        {
          path: "console",
          name: "console",
          meta: { roles: "DEVELOPER" },
          component: () => import(/* webpackChunkName: "web-console" */ "@/views/console")
        },
        {
          path: "sinistri",
          name: "sinistri",
          props: true,
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          meta: { forSocieta: "RCI"},
          component: () => import(/* webpackChunkName: "sinistri" */ "@/views/rci/sinistri-rci")
        },
        {
          path: "formazione",
          name: "formazione",
          props: true,
          meta: { forSocieta: "RCI"},
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "formazione" */ "@/views/rci/formazione")
        },
        {
          path: "alerts",
          name: "alerts",
          props: true,
          meta: { forSocieta: "RCI"},
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "alerts" */ "@/views/rci/alerts")
        },
        {
          path: "docs",
          name: "docs",
          props: true,
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
          component: () => import(/* webpackChunkName: "docs" */ "@/views/sinistri/docs")
        },
        {
          path: "/unauthorized",
          name: "unauthorized",
          beforeEnter(to, from, next) {
            if(!oAuth.isAuthenticated()) next({ name: "login" })
            else next()
          },
          component: () => import(/* webpackChunkName: "errors" */ "./views/errors/error-403")
        },
        {
          path: "/not-found",
          name: "not-found",
          beforeEnter(to, from, next) {
            if(!oAuth.isAuthenticated()) next({ name: "login" })
            else next()
          },
          component: () => import(/* webpackChunkName: "errors" */ "./views/errors/error-404")
        },
	      {
	      	path: "moduli-vettura-sostitutiva",
		      name: "moduli-vettura-sostitutiva",
		      meta: { forSocieta: "RCI", roles: ["MODULI_VETTURA_SOSTITUTIVA"] },
          beforeEnter: (to, from, next) => {
            var authorized = !(oAuth.hasRoles('PERITO_EXT') || oAuth.isSocieta('ALL'));
            if (authorized) {
              next();
            } else {
              next({ name: "unauthorized" });
            }
          },
		      component: nestedComponent("moduli-vettura-sostitutiva"),
		      children: [
			      {
			      	path: "",
			        name: "elenco-moduli",
			        component: () => import(/* webpackChunkName: "moduli-vettura-sostitutiva" */ "@/views/rci/moduli-vettura-sostitutiva/elenco-moduli"),
			      },
			      {
					path: ":compagnia",
			        name: "editor-moduli",
			        props: true,
			        component: () => import(/* webpackChunkName: "moduli-vettura-sostitutiva" */ "@/views/rci/moduli-vettura-sostitutiva/editor-moduli"),
			      }
		      ]
	      }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "login" */ "./views/login")
    },
    { path: "*", redirect: { name: "home" } }
  ]
})

router.beforeEach(routerBeforeEach)

export default router
