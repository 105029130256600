<script>
	import VTextField from "vuetify/es5/components/VTextField/VTextField"

	export default {
		name: "output-field",
		extends: VTextField,
		computed: {
			classes() {
				return {
					...VTextField.options.computed.classes.call(this),
					"output-field": true
				}
			}
		},
		methods: {
			genInput() {
				const data = {
					style: { cursor: "default" },
					attrs: {
						...this.attrs$,
						id: this.computedId,
						readonly: true,
						autofocus: this.autofocus,
						disabled: this.disabled
					},
					on: Object.assign({}, this.$listeners),
					class: "output-content",
					ref: "output"
				}
				return this.$createElement("span", data, [this.value])
			},
			onClick() { this.$emit("click") }
		}
	}
</script>

<style lang="stylus" scoped>
	.output-field::v-deep
		.v-input__slot
			&:before, &:after
				border-color: rgba(0, 0, 0, .42) !important
				border-style: dashed !important
			.output-content
				flex: 1 1 auto
				line-height: 20px
				padding: 8px 0 8px
				max-width: 100%
				min-width: 0
				width: 100%
				max-height: 32px
				min-height: 32px
				cursor: default
		&.v-input--dense
			.ui-output-content
				padding: 4px 0 2px
				min-height: 26px
</style>