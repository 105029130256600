<template>
	<v-text-field v-model="formattedValue"
								@keypress="onKeyPress"
								@input="onInput"
								@focus="focusHandler(true)"
								@blur="focusHandler(false)"
								v-bind="$attrs"
								ref="input"
	></v-text-field>
</template>

<script>

	export default {
		name: "v-number-field",
		props: {
			value: Number,
			decimals: {
				type: Number,
				default: 2
			}
		},
		data() {
			return {
				formattedValue: "",
				states: {
					start: false,
					decimalSeparator: false,
					integerCount: 0,
					decimalCount: 0,
					sign: false
				},
				isFocus: false
			}
		},
		computed: {
			numeralFormat() {
				let format = "0"
				if(this.decimals > 0) format += `.${"0".repeat(this.decimals)}`
				return format
			},
			parsedValue() { return this.$numeral(this.formattedValue).value() }
		},
		methods: {
			parse(value) { return this.$numeral(value).value() },
			format(value) { return !this._.isNil(value) ? this.$numeral(value).format(this.numeralFormat) : "" },
			preventInput(event, replacement = "") {
				event.preventDefault()
				event.stopPropagation()
				if(replacement !== "") this.formattedValue += replacement
			},
			setStart(start) {
				console.debug("v-number-field set start", start, this.states)
				if(start) {
					this.states.start = true
					this.states.integerCount++
				} else if(this.states.start) {
					this.states.start = this.states.decimalSeparator = false
					this.states.integerCount = 0
				}
			},
			setSign(sign) {
				this.states.sign = !!sign
			},
			addDigit(event) {
				console.debug("v-number-field add digit", event, this.states)
				if(this.states.decimalSeparator) {
					if(this.states.decimalCount < this.decimals) this.states.decimalCount++
					else this.preventInput(event)
				} else this.states.integerCount++
			},
			setDecimalSeparator(decimalSeparator) {
				console.debug("v-number-field set decimal separator", decimalSeparator, this.states)
				if(decimalSeparator) this.states.decimalSeparator = true
				else this.states.decimalSeparator = false
				this.resetDecimals()
			},
			resetCounters(value) {
				value = value || this.formattedValue
				console.debug("v-number-field reset counters", value)
				const decimalSeparatorIndex = value.indexOf(",")
				if(decimalSeparatorIndex > 0) {
					this.resetIntegers(value.substring(0, decimalSeparatorIndex).length)
					this.resetDecimals(value.length - 1 - decimalSeparatorIndex)
				} else this.resetIntegers(value.length)
			},
			resetIntegers(count) {
				count = count || 0
				if(count !== this.states.integerCount) {
					this.states.integerCount = count
					this.resetDecimals()
				}
			},
			resetDecimals(count) {
				this.states.decimalCount = count || 0
			},
			isCaretAtStart() {
				return this.$refs.input.$refs.input.selectionStart === 0
			},
			onKeyPress(e) {
				if(/[0-9]/.test(e.key)) {
					if(this.states.start) this.addDigit(e)
					else this.setStart(true)
				} else if(e.key === "-" && this.isCaretAtStart()) this.setSign(true)
				else if(/[.,]/.test(e.key) && !this.states.decimalSeparator) {
					this.setDecimalSeparator(true)
					if(e.key === ".") this.preventInput(e, ",")
				} else this.preventInput(e)
				console.debug("on key press", e, this.formattedValue, e.target.value)
			},
			onInput(value) {
				console.debug("on input", value, this.parse(value))
				if(value === "") this.setStart(false)
				else {
					if(!/[,]/.test(value)) this.setDecimalSeparator(false)
					if(!/[-]/.test(value)) this.setSign(false)
				}
				this.resetCounters(value)
			},
			focusHandler(isFocus) {
				this.isFocus = isFocus
				if(this.$attrs.disabled !== true && this.$attrs.readonly !== true) {
					if (isFocus) this.$refs.input.$refs.input.setSelectionRange(0, this.formattedValue.length)
					else this.formattedValue = this.format(this.parsedValue)
				}
			}
		},
		watch: {
			value: {
				handler(nuovo, vecchio) {
					console.debug("v-number-field watch value", nuovo, vecchio, this.isFocus)
					if(nuovo !== vecchio && !this.isFocus) this.formattedValue = this.format(nuovo)
				},
				immediate: true
			},
			parsedValue(nuovo, vecchio) {
				if(nuovo !== vecchio) this.$emit("input", nuovo)
			}
		}
	}
</script>
