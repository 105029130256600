<template>
	<v-menu v-model="menu" lazy v-bind="menuAttrs" :close-on-content-click="false"
					transition="scale-transition"
					offset-y
					full-width
					max-width="290px"
					min-width="290px">
		<template #activator="{ on }">
			<v-text-field :value="value"
										prepend-icon="far fa-calendar-alt"
										readonly v-on="on" v-bind="$attrs"
										:append-icon="clearable && !!value ? 'fas fa-times-circle' : ''"
										@click:append="clearDate"
			/>
		</template>
		<v-date-picker v-model="model" no-title v-bind="dateAttrs" :min="formattedMin" :max="formattedMax" locale="it-IT" :first-day-of-week="1" color="primary"/>
	</v-menu>
</template>

<script>
	export default {
		name: "v-date-field",
		inheritAttrs: false,
		props: {
			value: String,
			format: {
				type: String,
				default: "DD/MM/YYYY"
			},
			dateMin: String,
			dateMax: String,
			clearable: Boolean
		},
		data: () => ({
			menu: false
		}),
		computed: {
			model: {
				get() { return !!this.value ? this.formatForPicker(this.value) : this.value },
				set(value) {
					this.menu = false
					this.$emit("input", this.$moment(value).format(this.format))
				}
			},
			dateAttrs() { return this._.pickBy(this.$attrs, (value, key) => key.startsWith("date")) },
			menuAttrs() { return this._.pickBy(this.$attrs, (value, key) => key.startsWith("menu")) },
			formattedMin() { return this.formatForPicker(this.dateMin) },
			formattedMax() { return this.formatForPicker(this.dateMax) }
		},
		methods: {
			formatForPicker(date) {
				return date ? this.$moment(date, this.format).format("YYYY-MM-DD") : date
			},
			clearDate() { this.$emit("input", "") }
		}
	}
</script>