import Vue from "vue"
import { VTable, VTableColumn } from "@/components/v-table"
import VRemoteTable from "@/components/v-remote-table"
import VDateField from "@/components/v-date-field"
import VDatetimeField from "@/components/v-datetime-field"
import VPanel from "@/components/v-panel"
import VRemoteSelect from "@/components/v-remote-select"
import VNumberField from "@/components/v-number-field"
import VPageLoading from "@/components/v-page-loading"
import VSuccessDialog from "@/components/v-success-dialog"
import VWarningDialog from "@/components/v-warning-dialog"
import OutputField from "@/components/output-field"
import LavorazioneField from "@/components/lavorazione-field"
import { SweetalertIcon } from "vue-sweetalert-icons"

Vue.component(VTable.name, VTable)
Vue.component(VTableColumn.name, VTableColumn)
Vue.component(VRemoteTable.name, VRemoteTable)
Vue.component(VDateField.name, VDateField)
Vue.component(VDatetimeField.name, VDatetimeField)
Vue.component(VPanel.name, VPanel)
Vue.component(VRemoteSelect.name, VRemoteSelect)
Vue.component(VNumberField.name, VNumberField)
Vue.component(VPageLoading.name, VPageLoading)
Vue.component(VSuccessDialog.name, VSuccessDialog)
Vue.component(VWarningDialog.name, VWarningDialog)
Vue.component(OutputField.name, OutputField)
Vue.component(LavorazioneField.name, LavorazioneField)
Vue.component(SweetalertIcon.name, SweetalertIcon)

import securityMixin from "@/mixins/security-mixin"
Vue.mixin(securityMixin)