<template>
    <v-dialog v-model="show" :persistent="persistent" max-width="320px">
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <sweetalert-icon icon="success"/>
                    </v-col>
                    <v-col cols="12">
                        <p>{{message}}</p>
                        <slot/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <slot name="actions" v-bind="{ close }">
                    <v-btn class="body-2 font-weight-regular" color="success" block :to="{ name: 'home' }">Torna alla home</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
		name: "v-success-dialog",
        props: {
			value: Boolean,
            persistent: { type: Boolean, default: true },
            message: { type: String, required: true }
        },
        computed: {
			show: {
				get() { return this.value },
                set(show) { this.$emit("input", show) }
            }
        },
        methods: {
			close() {
				this.show = false
            }
        },
        beforeDestroy() { this.close() }
    }
</script>