
export const SET_WARNING = "SET_WARNING"
export const SET_EXCEPTION = "SET_EXCEPTION"

const app = {
	state: {
		warning: "",
		exception: ""
	},
	getters: {
		warning: state => state.warning,
		exception: state => state.exception
	},
	mutations: {
		[SET_WARNING]: (state, message) => state.warning = message,
		[SET_EXCEPTION]: (state, message) => state.exception = message,
		reset: state => {
			state.warning = ""
			state.exception = ""
		}
	},
	actions: {
		setWarning({ commit }, message) { commit(SET_WARNING, message) },
		clearWarning({ commit }) { commit(SET_WARNING, "") },
		setException({ commit }, message) { commit(SET_EXCEPTION, message) },
		clearException({ commit }) { commit(SET_EXCEPTION, "") },
		reset({ commit }) { commit("reset") }
	}
}

export default app