<script>
	export default {
		name: "v-column-cell",
		model: {
			prop: "item",
			event: "item:change"
		},
		props: {
			item: { required: false },
			index: Number,
			column: Object
		},
		data: (cell) => ({
			row: {
				item: cell.item,
				index: cell.index
			}
		}),
		methods: {
			renderCell() {
				if(this.$scopedSlots.default) return this.$scopedSlots.default(this.row);
				if(this.$slots.default) return this.$slots.default;
				if(!this._.isNil(this.column)) return this.column.renderCell(this.row);
			}
		},
		render(h) {
			const children = [this.renderCell()];
			const classes = (this.column && this.column.classes) || {}
			const css = this.column && this.column.css
			return h("td", { staticClass: "v-column-cell pa-2", class: classes, style: css }, children);
		},
		watch: {
			item: {
				handler(item) { this.row.item = item; }
			}
		}
	}
</script>