import store from "../store"
import router from "../router"
import axios from "axios"
import * as _ from "lodash"

const SOCIETA = process.env.VUE_APP_SOCIETA
const SOCIETA_HEADER = process.env.VUE_APP_SOCIETA_HEADER
const LOGIN_URL = process.env.VUE_APP_LOGIN_URL
const REFRESH_URL = process.env.VUE_APP_REFRESH_URL
const LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL
const LOGIN_AS_URL = process.env.VUE_APP_LOGIN_AS_URL
const GET_ROLES_URL = process.env.VUE_APP_GET_ROLES_URL
const UNAUTHORIZED_PAGE = process.env.VUE_APP_UNAUTHORIZED_PAGE

class OAuth {

    isAuthenticated() { return store.getters.isAuthenticated }
    
    isRefreshable() { return store.getters.isRefreshable }

    getUser(field) {
        const user = store.getters.user
        if(field && user.hasOwnProperty(field)) return user[field]
        else return user
    }

    async fetchUserAuthorities() {
        try {
            const response = await axios.get(GET_ROLES_URL)
            store.dispatch("setAuthorities", response.data)
        } catch(e) {
            store.dispatch("setAuthorities", [])
        }
    }

    getUserAuthorities() {
        return store.getters.authorities
    }

    getAccessToken() { return store.getters.accessToken }

    getRefreshToken() { return store.getters.refreshToken }
    
    parseRolesArray(roles, needAuth = true) {
        const array = []
        if(needAuth) {
            if(_.isArray(roles)) roles.forEach(role => array.push(...this.parseRolesArray(role)))
            else if(_.isString(roles)) roles.split(/[, ]/).filter(a => a).forEach(authority => array.push(authority.trim()))
            else array.push(roles)
        }
        return array
    }

    hasRoles(roles) {
        if(!this.isAuthenticated()) return false
        const parsedRoles = this.parseRolesArray(roles)
        return this.hasAnyRoles(parsedRoles, this.getUserAuthorities())
    }

    hasAnyRoles(roles, userRoles) {
        const check = _.reduce(roles, (check, role) => {
            if(userRoles.indexOf(role) >= 0) return true
            return check
        }, false)
        console.debug("has any roles", check, roles, userRoles)
        return check
    }

    isSocieta(societa) {
        return societa === SOCIETA
    }

    setBearerHeader(config, force = false) {
        const token = this.getAccessToken()
        if(!!token && (force || !config.headers["Authorization"])) {
            console.debug(`set bearer token${force ? " (forced)" : ""}`, token)
            config.headers["Authorization"] = "Bearer " + token
        }
    }

    setSocietaHeader(config) {
        if(!!SOCIETA && !!SOCIETA_HEADER) {
            console.debug(`set societa header ${SOCIETA_HEADER}`, SOCIETA)
            config.headers[SOCIETA_HEADER] = SOCIETA
        }
    }

    isAccessTokenInvalidOrExpired(response) {
        const error = response.data.error
        return (this.isUnauthorized(response) && (error === "invalid_token" || error === "expired_token"))
    }

    isRefreshTokenInvalid(response) {
        const error = response.data.error
        return (this.isBadRequest(response) && error === "invalid_grant")
    }

    isNotFound(response) { return response.status === 404 }
    
    isBadRequest(response) {
        const regex = new RegExp(/^.+\/api\/check_token\?token=.+$/)
        const isRefreshUrl = regex.test(response.request.responseURL)
        return !isRefreshUrl && response.status === 400
    }
    
    isServerError(response) { return response.status === 500 }
    
    isUnauthorized(response) { return response.status === 401 }
    
    getExpirationDate(expiresIn = 0) {
        let expirationDate = new Date()
        expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn)
        return expirationDate
    }
    
    parseToken(token = { access_token: "", refresh_token: "", expires_in: "0" }) {
        return {
            accessToken: token.access_token,
            refreshToken: token.refresh_token,
            expiresIn: token.expires_in,
            expirationDate: this.getExpirationDate(token.expires_in)
        }
    }

    pushRoute(route) {
        router.push(route)
    }

    async login(username, password) {
        const headers = { "Content-type": "multipart/form-data", "with-authorities": "" }
        const data = new FormData()
        data.append("grant_type", "password")
        data.append("username", username)
        data.append("password", password)
        try {
            const response = await axios.post(LOGIN_URL, data, { headers: headers })
            const token = response.data
            const utente = token.user
            const authorities = token.authorities
            store.commit("LOGIN", { token: this.parseToken(token), utente, authorities })
            const path = !!router.currentRoute.query.p ? atob(router.currentRoute.query.p) : "/"
            store.dispatch("showAlert")
            this.pushRoute({ path: path })
        } catch(e) {
            store.commit("LOGIN_ERROR")
        }
    }
    
    async refresh(config) {
        const headers = {
            "Content-type": "multipart/form-data",
            "with-authorities": ""
        }
        const data = new FormData()
        data.append("grant_type", "refresh_token")
        data.append("refresh_token", this.getRefreshToken())
        try {
            const response = await axios.post(REFRESH_URL, data, {headers: headers})
            const token = response.data
            store.commit("REFRESH", this.parseToken(token))
            if(!_.isUndefined(config)) this.retry(config)
            else return true
        } catch(e) {
            this.pushRoute({ name: "login" })
        }
    }
    
    async logout() {
        try {
            await axios.post(LOGOUT_URL)
            store.commit("LOGOUT")
            if(this.isAuthenticated()) {
                this.fetchUserAuthorities()
                if(router.currentRoute.name === "home") router.go()
                else this.pushRoute({ name: "home" })
            } else this.pushRoute({ name: "login" })
        } catch(e) {
            console.error("Logout error", e)
            this.pushRoute({ name: "login" })
        }
    }

    async loginAs(username) {
        const headers = { "with-authorities": "" }
        try {
            const response = await axios.post(LOGIN_AS_URL, { username: username }, { headers: headers })
            const token = response.data
            const utente = token.user
            const authorities = token.authorities
            store.dispatch("loginAs", { token: this.parseToken(token), utente, authorities })
            store.dispatch("setAuthorities", authorities)
            this.pushRoute({ path: "home" })
        } catch(e) {
            store.dispatch("loginAsError")
        }
    }
    
    retry(config) {
        this.setBearerHeader(config, true)
        this.setSocietaHeader(config)
        console.debug("retry request", config)
        return axios(config)
    }

}

const oauth = new OAuth()

export function axiosRequestGuard(config) {
    oauth.setBearerHeader(config)
    oauth.setSocietaHeader(config)
    return config
}

export async function axiosResponseGuard(error) {
    if(error && error.response) {
        const response = error.response
        if(oauth.isAccessTokenInvalidOrExpired(response) && oauth.isRefreshable()) {
            return oauth.refresh(error.config)
        } else if(oauth.isUnauthorized(response)) {
            console.debug("unauthorized request")
            oauth.pushRoute({ name: "unauthorized" })
            return
        } else if(oauth.isBadRequest(response)) {
            console.debug("bad request", response.data)
            const message = response.data.message ? response.data.message : "Errore imprevisto"
            store.dispatch("setException", message)
        }
    }
    console.debug("error was not handled by axios guard", error.response)
    throw error
}

export function routerGuard(check, to, from, next) {
    if(!oauth.isAuthenticated() && from.name == "login") {
        next({ name: "login" })
        return
    }
    if(check && to.name !== "login") {
        let name = "login", query = {}
        if(oauth.isAuthenticated()) name = UNAUTHORIZED_PAGE
        else query = { p: btoa(to.fullPath) }
        next({ name: name, query: query })
        console.debug("router guard, you shall not pass, go to", name)
        return
    }
    console.debug("router guard grant access to", to.fullPath)
    next()
}

export function routerBeforeEach(to, from, next) {
    const needRoles = to.matched.find(route => route.meta.roles)
    const needAuthentication = to.matched.find(route => route.meta.isAuthenticated)
    const needSocieta = to.matched.find(route => route.meta.forSocieta)
    let check = true
    if(!_.isNil(needRoles)) check &= !!oauth.hasRoles(needRoles.meta.roles)
    if(!_.isNil(needAuthentication)) check &= !!oauth.isAuthenticated()
    if(!_.isNil(needSocieta)) check &= !!oauth.isSocieta(needSocieta.meta.forSocieta)
    return routerGuard(!check, to, from, next)
}

export default oauth