<template>
    <v-row class="v-time-select">
        <v-col mr-2>
            <v-select v-model="ora" :items="ore" label="ore" :disabled="disabled"/>
        </v-col>
        <v-col :mr-2="withSeconds">
            <v-select v-model="minuto" :items="minuti" label="minuti" :disabled="disabled"/>
        </v-col>
        <v-col v-if="withSeconds">
            <v-select v-model="secondo" :items="secondi" label="secondi" :disabled="disabled"/>
        </v-col>
    </v-row>
</template>

<script>
	export default {
		name: "v-time-select",
        props: {
			value: String,
	        format: { type: String, default: "HH:mm:ss" },
            withSeconds: Boolean,
            disabled: Boolean
        },
        data() {
			return {
				ore: this._.range(0, 24),
                minuti: this._.range(0, 60),
                secondi: this._.range(0, 60)
            }
        },
        computed: {
			model: {
                get() { return this.value },
                set(model) { this.$emit("input", model) }
            },
            ora: {
                get() { return +this.formatOre(this.model) },
                set(ora) { this.model = this.$moment(`${ora}:${this.minuto}:${this.secondo}`, "HH:mm:ss").format(this.format) },
            },
            minuto: {
	            get() { return +this.formatMinuti(this.model) },
	            set(minuto) { this.model = this.$moment(`${this.ora}:${minuto}:${this.secondo}`, "HH:mm:ss").format(this.format) },
            },
	        secondo: {
		        get() { return +this.formatSecondi(this.model) },
		        set(secondo) {  this.model = this.$moment(`${this.ora}:${this.minuto}:${secondo}`, "HH:mm:ss").format(this.format)  },
	        }
        },
        methods: {
            formatOre(ore) { return !!ore ? this.$moment(ore, this.format).format("HH") : ore },
            formatMinuti(minuti) { return !!minuti ? this.$moment(minuti, this.format).format("mm") : minuti },
            formatSecondi(secondi) { return !!secondi ? this.$moment(secondi, this.format).format("ss") : secondi },
        }
	}
</script>