<template>
    <v-menu v-model="showMenu" offset-y :close-on-content-click="false" max-width="290">
        <template #activator="{ on }">
            <v-text-field :value="value" v-bind="$attrs" v-on="on" prepend-icon="far fa-calendar-alt"/>
        </template>
        <v-date-picker v-model="dateModel" :min="dateMin" :max="dateMax" locale="it-it" color="primary" no-title>
            <v-time-select v-model="timeModel" :disabled="!dateModel"/>
        </v-date-picker>
    </v-menu>
</template>

<script>
    import VTimeSelect from "./v-time-select"

    export default {
        name: "v-datetime-field",
        inheritAttrs: false,
        components: { VTimeSelect },
        props: {
        	show: Boolean,
            value: String,
            format: { type: String, default: "DD/MM/YYYY HH:mm" },
            min: String,
            max: String,
            hideActions: Boolean
        },
        data() {
	        return {
	        	lazyShow: false,
                menu: "date"
            }
        },
        computed: {
        	showMenu: {
        		get() { return this.lazyShow },
                set(show) {
        			this.lazyShow = show
                    if(!show) this.menu = "date"
        			this.$emit("update:show", this.lazyShow)
        		}
            },
            model: {
        		get() { return this.value },
                set(model) { this.$emit("input", model) }
            },
            dateModel: {
        		get() { return this.formatDate(this.model) },
                set(date) {
        			this.menu = "time"
        			if(!!date) {
                        if(!!this.timeModel) this.model = this.$moment(`${date} ${this.timeModel}`, "YYYY-MM-DD HH:mm:ss").format(this.format)
                        else this.model = this.$moment(date, "YYYY-MM-DD HH:mm:ss").format(this.format)
                    }
        		}
            },
            timeModel: {
                get() { return this.formatTime(this.model) },
                set(time) {
                	if(!!time) {
                		if(!!time) this.model = this.$moment(`${this.dateModel} ${time}`, "YYYY-MM-DD HH:mm:ss").format(this.format)
                    }
                }
            },
            dateMin() { return this.formatDate(this.min) },
            dateMax() { return this.formatDate(this.max) },
        },
        methods: {
        	formatDate(date) { return !!date ? this.$moment(date, this.format).format("YYYY-MM-DD") : date },
            formatTime(time) { return !!time ? this.$moment(time, this.format).format("HH:mm:ss") : time }
        },
        watch: {
        	show(value) { this.lazyShow = value }
        }
    }
</script>

<style scoped>
  /deep/ .v-picker__title {
    min-height: 105px;
  }
</style>