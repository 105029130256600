import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import security, { CLEAR_SECURITY } from "./vuex-modules/security-module"
import sinistri, { CLEAR_SINISTRI } from "./vuex-modules/sinistri-module"
import app, { SET_WARNING, SET_EXCEPTION } from "./vuex-modules/app-module"
import notifiche, { CLEAR_CONTEGGIO_NOTIFICHE } from "./vuex-modules/notifiche-module"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { app, security, sinistri, notifiche },
  plugins: [
    createPersistedState({ key: "vuex-portale-sinistri" })
  ],
  actions: {
    reset({ commit }) {
      commit(CLEAR_SECURITY)
      commit(CLEAR_SINISTRI)
      commit(SET_EXCEPTION, "")
      commit(SET_WARNING, "")
	  commit(CLEAR_CONTEGGIO_NOTIFICHE)
    }
  }
})
