import Vue from "vue"
import Vuetify from "vuetify"
import it from "vuetify/es5/locale/it"

const SOCIETA = process.env.VUE_APP_SOCIETA

const themes = {
  BMW: {
    primary: "#1C69D4"
  },
  RCI: {
    primary: "#F47216",
    warning: "#F47216"
  },
  VW: {
    primary: "#1A2C5D"
  },
  ALL: {
    primary: '#6731A1',
    warning: "#6731A1"
  }
}

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: Object.assign({}, {
        primary: "#1C69D4",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }, themes[SOCIETA])
    }
  },
  icons: {
    iconfont: "fa"
  },
  iconfont: "fa",
  lang: {
    locales: { it },
    current: "it"
  },
})