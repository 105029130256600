<template>
	<v-layout fill-height align-center justify-center>
		<v-progress-circular indeterminate :size="200" :width="10" color="primary">Caricamento...</v-progress-circular>
	</v-layout>
</template>

<script>
	export default {
		name: "v-page-loading"
	}
</script>