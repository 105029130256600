<script>
	export default {
		name: "v-table-column",
		inheritAttrs: false,
		props: {
			header: String,
			footer: String,
			prop: String,
			sortProp: String,
			sortable: {
				type: Boolean,
				default: false
			},
			formatValue: {
				type: Function,
				default: value => value
			},
			css: [String, Object, Array]
		},
		data: () => ({
			columnId: ""
		}),
		computed: {
			classes() {
				const classes = {};
				this._.each(this.$attrs, (v, k) => classes[k] = this._.isBoolean(v) ? v : true);
				return classes;
			}
		},
		methods: {
			getValue(item) { return !this._.isNil(this.prop) ? this._.get(item, this.prop) : item && item.toString(); },
			renderHeader(payload) {
				if(this.$scopedSlots.header) return this.$scopedSlots.header(payload);
				if(this.$slots.header) return this.$slots.header;
				return this.header;
			},
			renderFooter(items) {
				if(this.$scopedSlots.footer) return this.$scopedSlots.footer(items);
				if(this.$slots.footer) return this.$slots.footer;
				return this.footer;
			},
			renderCell(data) {
				if(this.$scopedSlots.default) return this.$scopedSlots.default(data);
				if(this.$slots.default) return this.$slots.default;
				const value = this.getValue(data.item);
				return this.formatValue ? this.formatValue(value) : value;
			}
		},
		render(h) { return h("col", {}, []); },
		created() {
			this.columnId = this._.uniqueId("v-table-column-");
			console.debug("v-table-column created", this.columnId);
		}
	}
</script>