<script>
	export default {
		name: "v-column-footer",
		props: {
			items: Array,
			column: {
				type: Object,
				required: true
			}
		},
		methods: {
			renderFooter() {
				if(this.$scopedSlots.default) return this.$scopedSlots.default(this.items);
				if(this.$slots.default) return this.$slots.default;
				if(!this._.isNil(this.column)) return this.column.renderFooter(this.items);
			}
		},
		render(h) {
			const children = [this.renderFooter()];
			const classes = (this.column && this.column.classes) || {}
			const css = this.column && this.column.css
			return h("td", { staticClass: "v-column-footer pa-2", class: classes, style: css }, children);
		}
	}
</script>