import { render, staticRenderFns } from "./v-page-loading.vue?vue&type=template&id=3a621c03"
import script from "./v-page-loading.vue?vue&type=script&lang=js"
export * from "./v-page-loading.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports