<template>
	<v-layout class="v-table-paginator" row wrap justify-space-between>
		<v-flex xs12 sm4 class="caption ml-4 mt-5 mb-5 v-table-max" align-center justify-start>
			Mostra <v-select :value="max" @input="updateMax" hide-details :items="maxes" menu-props="auto" class="mx-2 my-0" no-data-text="tutti"></v-select> di {{total}}
		</v-flex>
		<v-flex xs12 sm6 class="v-table-offset mt-5 mb-5 mr-4" align-center :justify-end="$vuetify.breakpoint.smAndUp" :justify-center="$vuetify.breakpoint.xsOnly">
			<v-btn small color="primary" class="prev mb-0 mr-1" @click="updatePage(1)" :disabled="prevDisabled">
				<v-icon>fas fa-angle-double-left</v-icon>
			</v-btn>
			<v-btn small color="primary" class="prev mb-0 ml-1 mr-1" @click="updatePage(page - 1)" :disabled="prevDisabled">
				<v-icon>fas fa-angle-left</v-icon>
			</v-btn>
			<v-select :value="page" @input="updatePage" hide-details :items="pages" menu-props="auto" class="mx-1 my-0" no-data-text="pagina 1"></v-select>
			<v-btn small color="primary" class="next mb-0 ml-1 mr-1" @click="updatePage(page + 1)" :disabled="nextDisabled">
				<v-icon>fas fa-angle-right</v-icon>
			</v-btn>
			<v-btn small color="primary" class="next mb-0 ml-1" @click="updatePage(maxPage)" :disabled="nextDisabled">
				<v-icon>fas fa-angle-double-right</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>

<script>
	const DEFAULT_MAX = +process.env.VUE_APP_DEFAULT_MAX
	const DEFAULT_PAGE = +process.env.VUE_APP_DEFAULT_PAGE

	export default {
		name: "v-table-pagination",
		model: {
			prop: "pagination",
			event: "update-pagination"
		},
		props: {
			max: {
				type: Number,
				default: DEFAULT_MAX
			},
			page: {
				type: Number,
				default: DEFAULT_PAGE
			},
			total: {
				type: Number,
				default: 0
			}
		},
		computed: {
			maxes() {
				const maxes = [1, 5, 10, 20, 50, 100]
				if(this.max < this.total && !this._.includes(maxes, this.max)) maxes.push(this.max)
				return this._.sortBy(maxes)
			},
			pages() {
				if(this.total < this.max) return [{ text: "1", value: 1 }]
				else {
					const totalPages = Math.ceil(this.total / this.max)
					return this._.times(totalPages, n => ({ text: `${n + 1}`, value: n + 1 }))
				}
			},
			maxPage() {
				const maxPageItem = this._.maxBy(this.pages, page => page.value)
				if(this._.isUndefined(maxPageItem)) return 1
				else return maxPageItem.value
			},
			prevDisabled() { return this.page === 1 },
			nextDisabled() { return this.page === this.maxPage }
		},
		methods: {
			updateMax(max) { if(max !== this.max) this.updatePagination(max, 1) },
			updatePage(page) { if(page !== this.page) this.updatePagination(this.max, page) },
			updatePagination(max, page) {
				console.debug("v-table-pagination updatePagination", { page: page, max: max })
				this.$emit("update-pagination", { page: page, max: max })
			},
		}
	}
</script>

<style scoped>
  .v-table-paginator {
    display: flex;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap-reverse;
  }

  .v-table-paginator .v-table-offset,
  .v-table-paginator .v-table-max {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .v-table-paginator .v-table-offset .v-select /deep/,
  .v-table-paginator .v-table-max .v-select /deep/ {
    min-width: 50px;
    flex: 0 1 0;
    margin: 13px 0 13px 34px;
    padding: 0;
    position: initial;
  }

  .v-table-paginator .v-table-offset .v-select /deep/ input,
  .v-table-paginator .v-table-max .v-select /deep/ input {
    font-size: 12px;
  }

  .v-table-paginator .v-table-offset .v-select /deep/ .v-select__selections,
  .v-table-paginator .v-table-max .v-select /deep/ .v-select__selections {
    flex-wrap: nowrap;
  }

  .v-table-paginator .v-table-offset .v-select /deep/ .v-select__selections .v-select__selection--comma,
  .v-table-paginator .v-table-max .v-select /deep/ .v-select__selections .v-select__selection--comma {
    font-size: 12px;
  }

  .v-table-paginator .v-table-offset .prev,
  .v-table-paginator .v-table-max .prev,
  .v-table-paginator .v-table-offset .next,
  .v-table-paginator .v-table-max .next {
    min-width: 40px;
  }
</style>