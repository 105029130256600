
export const LOGIN = "LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const REFRESH = "REFRESH"
export const REFRESH_ERROR = "REFRESH_ERROR"
export const LOGOUT = "LOGOUT"
export const LOGIN_AS = "LOGIN_AS"
export const LOGIN_AS_ERROR = "LOGIN_AS_ERROR"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const SET_AUTHORITIES = "SET_AUTHORITIES"
export const CLEAR_SECURITY = "CLEAR_SECURITY"

const initialErrors = {
	login: false,
	refresh: false,
	loginAs: false
}

const initialUser = {
	id: null,
	nome: "",
	ruolo: "",
	societa: []
}

const initialToken = {
	accessToken: "",
	refreshToken: "",
	expiresIn: 0,
	expirationDate: new Date()
}

const initialSession = {
	user: { ...initialUser },
	token: { ...initialToken },
	authorities: []
}

const security = {
	state: {
		errors: { ...initialErrors },
		session: { ...initialSession },
		stack: []
	},
	getters: {
		user: state => state.session.user,
		token: state => state.session.token,
		authorities: state => state.session.authorities,
		previousUser: (state, getters) => getters.isImpersonated ? state.stack[state.stack.length - 1].user : null,
		accessToken: (state, getters) => getters.token.accessToken,
		refreshToken: (state, getters) => getters.token.refreshToken,
		isAuthenticated: (state, getters) => !!getters.accessToken,
		isRefreshable: (state, getters) => !!getters.refreshToken,
		isImpersonated: state => state.stack.length > 0,
		isAccessTokenValid: (state, getters) => getters.isAuthenticated && getters.token.expirationDate > new Date(),
		loginError: state => state.errors.login,
		refreshError: state => state.errors.refresh,
		loginAsError: state => state.errors.loginAs,
		hasErrors: (state, getters) => getters.loginError || getters.refreshError || getters.loginAsError
	},
	mutations: {
		[LOGIN]: (state, { token, utente, authorities }) => {
			state.session = {
				token: token,
				user: utente,
				authorities: authorities
			}
			state.errors.login = false
		},
		[LOGIN_ERROR]: state => state.errors.login = true,
		[REFRESH]: (state, token) => state.session.token = token,
		[REFRESH_ERROR]: state => state.errors.refresh = true,
		[LOGOUT]: state => state.session = state.stack.pop() || { ...initialSession },
		[LOGIN_AS]: (state, { token, utente, authorities }) => {
			state.stack.push(state.session)
			state.session = {
				token: token,
				user: utente,
				authorities: authorities
			}
			state.errors.loginAs = false
		},
		[LOGIN_AS_ERROR]: state => state.errors.loginAs = true,
		[CLEAR_ERRORS]: state => state.errors = { ...initialErrors },
		[SET_AUTHORITIES]: (state, authorities) => state.session.authorities = authorities,
		[CLEAR_SECURITY]: state => {
			state.errors = { ...initialErrors }
			state.session = { ...initialSession }
			state.stack = []
		}
	},
	actions: {
		login( { commit }, payload) {
			commit(LOGIN, payload)
		},
		loginError({ commit }) {
			commit(LOGIN_ERROR)
		},
		refresh( { commit }, token) {
			commit(REFRESH, token)
		},
		refreshError({ commit }) {
			commit(REFRESH_ERROR)
		},
		logout({ commit }) {
			commit(LOGOUT)
		},
		loginAs({ commit }, payload) {
			commit(LOGIN_AS, payload)
		},
		loginAsError({ commit }) {
			commit(LOGIN_AS_ERROR)
		},
		clearErrors({ commit }) {
			commit(CLEAR_ERRORS)
		},
		setAuthorities({ commit }, authorities) {
			commit(SET_AUTHORITIES, authorities)
		},
		clearSecurity({ commit }) {
			commit(CLEAR_SECURITY)
		}
	}
}

export default security